
	
	.breadcum{
		padding: 30px 0;
		background: url("../img/breadcum.jpg");
		border-top: 1px solid $gray-lignt;	
	 //    border-bottom: 1px solid $gray-lignt;
		// box-shadow: 1px 1px 5px 0px #e8e8e8 inset;
		h4{
			text-transform: uppercase;
	        font-size: 100%;
	        // font-size: 38px;
			text-align: center;
			color: $white-color;
		}
		a{
			color: $white-color;
		    font-size: 16px;
		}
	}
